<template>
  <div class="m-2 rounded-2xl">
    <div class="py-2 sm:px-6">
      <BaseCard title="Riwayat Transaksi">
        <template #action>
          <base-button
            @click="
              $router.push('transaksi-penjualan/tambah-transaksi-penjualan')
            "
            type="button"
            :disabled="getOrders.meta.user_draft_count >= 1"
            id="tambah_transaksi"
            :title="
              getOrders.meta.user_draft_count >= 1
                ? 'Tidak dapat membuat penjualan karena masih terdapat penjualan draft pada akun ini'
                : null
            "
            :class="
              getOrders.meta.user_draft_count >= 1
                ? 'cursor-not-allowed opacity-50'
                : null
            "
          >
            <Icon icon="heroicons:plus-20-solid" class="h-5 w-5" />
            Tambah Transaksi
          </base-button>
        </template>
        <div class="flex flex-col">
          <div class="flex items-end justify-end">
            <div class="relative my-4 mx-2 w-2/12 rounded-md shadow-sm">
              <!-- origin office -->
              <label
                for="kantor_pembuat"
                class="mb-2 block text-sm font-medium text-gray-700"
                >Kantor Pembuat</label
              >
              <select
                id="pembuat"
                v-model="filter.officeTypeCreator"
                @change="handleChangeFilter"
                class="inline-flex w-full appearance-none justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm"
              >
                <option :value="null">Semua</option>
                <option
                  v-for="(item, i) in mapDestinationToOrigin"
                  :value="item"
                  :key="i"
                >
                  {{
                    item
                      .split('_')
                      .map(
                        (it) =>
                          it.charAt(0).toUpperCase() +
                          it.substr(1).toLowerCase()
                      )
                      .join(' ')
                  }}
                </option>
              </select>
            </div>
            <div class="relative my-4 mx-2 w-2/12 rounded-md shadow-sm">
              <!-- destination office -->
              <label
                for="destinasi_kantor"
                class="mb-2 block text-sm font-medium text-gray-700"
                >Destinasi Kantor</label
              >
              <select
                v-model="selectedDestinationOffice"
                @change="filterByOffice"
                id="destination"
                class="inline-flex w-full appearance-none justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm"
              >
                <option
                  :value="null"
                  v-if="filter.officeTypeCreator !== 'stockist'"
                >
                  Semua
                </option>
                <option
                  v-for="(item, i) in mapOriginToDestination"
                  :value="item"
                  :key="i"
                >
                  {{
                    item
                      .split('_')
                      .map((it) => it.charAt(0).toUpperCase() + it.substr(1))
                      .join(' ')
                  }}
                </option>
              </select>
            </div>
            <div class="relative my-4 mx-2 w-2/12 rounded-md shadow-sm">
              <label
                for="status_faktur"
                class="mb-2 block text-sm font-medium text-gray-700"
                >Status Faktur</label
              >
              <select
                v-model="filter.is_valid_for_payment"
                @change="handleChangeFilter"
                id="destination"
                class="inline-flex w-full appearance-none justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm"
              >
                <option :value="null">Semua</option>
                <option :value="true">Valid</option>
                <option
                  v-if="
                    selectedDestinationOffice === 'stockist_center' ||
                    selectedDestinationOffice === 'kantor_pelayanan'
                  "
                  value="reported"
                >
                  Valid Laporan
                </option>
                <option :value="false">Draft</option>
                <option value="cancel">Cancel</option>
                <option value="deleted">Hapus</option>
              </select>
            </div>
            <div class="relative my-4 mx-2 w-2/12 rounded-md shadow-sm">
              <label
                for="status_faktur"
                class="mb-2 block text-sm font-medium text-gray-700"
                >Laporan Tervalidasi</label
              >
              <select
                v-model="filter.orderReportedTo"
                @change="handleChangeFilter"
                id="destination"
                class="inline-flex w-full appearance-none justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm"
              >
                <option :value="null">Pilih Status</option>
                <option :value="'all'">Tervalidasi</option>
                <option :value="'not_reported'">Belum Tervalidasi</option>
                <option :value="'Stockist Center'">Stockist Center</option>
                <option :value="'Kantor Pelayanan'">Kantor Pelayanan</option>
              </select>
            </div>
            <div class="relative">
              <base-search
                style="margin: 16px 0"
                placeholder="Cari Kode"
                v-model="search"
                v-on:input="debounceSearch"
              />
            </div>
          </div>
          <div class="my-2 -flex overflow-auto sm:-mx-6 lg:-mx-8">
            <div
              class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
            >
              <PenjualanTable
                @click-row="onClickRow"
                @page-change="onPageChangeOrder"
              />
            </div>
          </div>
        </div>
      </BaseCard>

      <transition name="fade">
        <Modal2 v-if="confirmationEdit" @close-modal="confirmationEdit = false">
          <template v-slot:header>
            <h3
              class="text-lg font-semibold leading-6 text-gray-900"
              id="modal-title"
            >
              Edit Faktur Penjualan
            </h3>
          </template>
          <template v-slot:footer>
            <div class="mt-5 sm:mt-6 sm:flex sm:flex-row sm:gap-x-3">
              <base-button type="button" @click="editOrder(dataPenjualan)">
                Ya
              </base-button>
              <base-button
                @click="confirmationEdit = !confirmationEdit"
                type="button"
                color="white"
              >
                Tutup
              </base-button>
            </div>
          </template>
        </Modal2>
      </transition>

      <!-- MODAL EDIT CONFIRMATION-->
      <transition
        name="customFade"
        enter-active-class="fadeIn"
        leave-active-class="fadeOut"
      >
        <div
          v-if="confirmationEdit"
          style="animation-duration: 0.3s"
          class="fixed inset-0 z-20"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div
            class="flex mx-4 min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
          >
            <div
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              class="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
              >&#8203;</span
            >
            <div
              class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
            >
              <div>
                <div
                  class="flex mx-auto h-12 w-12 items-center justify-center rounded-full bg-red-100"
                >
                  <!-- Heroicon name: outline/check -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 text-red-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <h3
                    class="text-lg font-medium leading-6 text-gray-900"
                    id="modal-title"
                  >
                    Edit Faktur Penjualan
                  </h3>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">
                      Faktur Penjualan yang lama akan dihapus dan diganti faktur
                      yang baru. Apakah anda yakin?
                    </p>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6 sm:flex sm:flex-row sm:gap-x-3">
                <base-button
                  type="button"
                  fullwidth
                  @click="editOrder(dataPenjualan)"
                >
                  Ya
                </base-button>
                <base-button
                  @click="confirmationEdit = !confirmationEdit"
                  type="button"
                  fullwidth
                >
                  Tutup
                </base-button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <!-- Modal Detail -->
    <OrderDetailModal
      :visible="openDetail"
      :order="dataPenjualan"
      @close="() => (openDetail = false)"
      with-stock-movement
      with-bonus
      @refresh="handleRefreshOrders"
      @edit="openEditOrder"
      @delete="confirmationDelete = !confirmationDelete"
      with-delete-confirmation
      @open-prev-order="handleOpenPrevOrder"
      @detail-stock-movement="handleDetailStockMovement"
    />

    <!-- Modal Faktur Awal -->
    <OrderDetailModal
      title="Faktur Awal"
      description="Data Faktur Awal"
      :visible="visiblePrevOrderModal"
      :order="getPreviousOrder.data"
      :editable="false"
      :deleteable="false"
      :with-order-details="false"
      :with-payments="false"
      :with-actions="false"
      with-stock-movement
      @close="visiblePrevOrderModal = false"
    />

    <OrderCreatePaymentModal
      :order-id="orderCreatePaymentFormProps.orderId"
      v-model="orderCreatePaymentFormProps.visible"
      v-on:success="handleSuccessCreatePayment"
    />

    <StockMovementModal
      :visible="openDetailStockMovement"
      :with-dispute="false"
      :with-confirm="false"
      :with-send="false"
      @close="handleCloseDetailStockMovement"
    />

    <loading v-if="loading" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import dayjs from 'dayjs';
import BaseSearch from '@/components/base/Search.vue';
import Modal2 from '@/components/Modal2.vue';
import { StorageService } from '@/services/storage.service';
import OrderDetailModal from '@/components/order/OrderDetailModal';
import OrderCreatePaymentModal from '@/components/order/OrderCreatePaymentModal';
import StockMovementModal from '@/components/stock-movement/StockMovementModal.vue';
import { formatDate } from '@/utils/date';
import BaseCard from '@/components/base/BaseCard.vue';
import PenjualanTable from '@/components/penjualan/PenjualanTable.vue';

export default {
  name: 'Home',
  components: {
    Modal2,
    BaseCard,
    BaseSearch,
    OrderDetailModal,
    OrderCreatePaymentModal,
    StockMovementModal,
    PenjualanTable,
  },

  data() {
    return {
      openDetailStockMovement: false,
      search: null,
      filter: {
        officeTypeCreator: null,
        is_valid_for_payment: null,
        orderReportedTo: null, // enum
      },
      openDetail: false,
      confirmationDelete: false,
      deleteStatus: false,
      confirmationEdit: false,
      dataPenjualan: {},
      previousOrder: {},
      origin: {
        pusat: ['stockist', 'KYN', 'SCN', 'PRSH'],
        stockist: ['stockist_center', 'kantor_pelayanan', 'pusat'],
        kantor_pelayanan: ['stockist'],
        stockist_center: ['stockist'],
      },
      destination: {
        stockist: ['pusat', 'kantor_pelayanan', 'stockist_center'],
        KYN: ['pusat'],
        SCN: ['pusat'],
        PRSH: ['pusat'],
        stockist_center: ['stockist'],
        kantor_pelayanan: ['stockist'],
        pusat: ['stockist'],
      },
      dataPrint: {
        faktur: {
          no_invoice: null,
          tanggal: null,
          wilayah_harga: null,
          nama_pemilik: null,
          kode_gudang: null,
          phone: null,
        },
        barang: [
          {
            kode_barang: null,
            nama_barang: null,
            is_point: null,
            jumlah: null,
            harga: null,
            total_harga: null,
            total_berat: null,
            bonus: 0,
            grand_total_harga: 0,
          },
        ],
      },
      selectedDestinationOffice: null,
      cetakPdf: {
        preview: false,
        download: false,
        filename: '',
      },
      visiblePrevOrderModal: false,
      orderCreatePaymentFormProps: {
        orderId: null,
        visible: false,
      },
    };
  },

  computed: {
    ...mapGetters({
      getOrders: 'orders/getOrders',
      getOrder: 'orders/getOrder',
      getPreviousOrder: 'orders/getPreviousOrder',
      isOrderLoading: 'orders/getLoading',
      isStockMovementLoading: 'stock_movements/getLoading',
    }),
    loading() {
      return this.isOrderLoading || this.isStockMovementLoading;
    },
    mapOriginToDestination() {
      if (this.filter.officeTypeCreator === null) {
        return this.origin['pusat'];
      }
      return this.origin[this.filter.officeTypeCreator];
    },
    mapDestinationToOrigin() {
      let res = [];
      if (this.selectedDestinationOffice !== null) {
        return this.destination[this.selectedDestinationOffice];
      }
      for (const props in this.origin) {
        if (
          this.origin[this.selectedDestinationOffice]?.includes(
            this.filter.officeTypeCreator
          )
        ) {
          res.push(props);
        }
      }
      return res.length > 0
        ? res
        : ['pusat', 'kantor_pelayanan', 'stockist_center', 'stockist'];
    },
    fetchOrdersParams: function () {
      const params = {
        'filter[origin_office_category_id]': 1,
        // 'filter[destination_office_category_id]': 4,
        include:
          'destination-office,origin-office,origin-warehouse,period,order',
        fields: {
          'simple-orders':
            'order,origin-office,destination-office,order_id,period,order_status,warehouse_status,origin_code,destination_code',
          orders:
            'latest_update_by,destination-office,grand_total_price,origin-office,createdAt,updatedAt,deletedAt,origin_code,destination_code,final_price,is_valid_for_payment,destination_office_code,origin_warehouse_code,order_status,has_bundle,is_minus_bv,payment_status,has_payment_methods,period,final_price,grand_total_price',
          offices: 'code',
        },
        'page[limit]': 5,
      };

      const officeTypeCreator = this.filter.officeTypeCreator;
      if (this.filter.officeTypeCreator === 'stockist') {
        const parsedCategory = this.selectedDestinationOffice
          .split('_')
          .map((it) => it.charAt(0).toUpperCase() + it.substr(1))
          .join(' ');
        params['origin_office_category'] = parsedCategory;
      }

      if (this.filter.is_valid_for_payment == 'deleted') {
        params['filter[order_status]'] = 'Terhapus';
      } else if (this.filter.is_valid_for_payment == 'cancel') {
        params['order_status'] = 'Cancel';
      } else if (this.filter.is_valid_for_payment === 'reported') {
        params['order_status'] = 'Terlaporkan';
      } else if (this.filter.is_valid_for_payment === false) {
        params['order_status'] = 'Draft';
      } else {
        params['order_status'] = 'Diproses Kasir';
      }

      if (this.selectedDestinationOffice == 'stockist') {
        params['filter[search]'] = '';
      } else if (this.selectedDestinationOffice == 'KYN') {
        params['filter[search]'] = 'KYN';
      } else if (this.selectedDestinationOffice == 'SCN') {
        params['filter[search]'] = 'SCN';
      } else if (this.selectedDestinationOffice == 'PRSH') {
        params['filter[search]'] = 'PRSH';
      } else {
        params['filter[search]'] = this.search;
      }

      if (this.filter.orderReportedTo) {
        if (this.filter.orderReportedTo == 'not_reported') {
          params['filter[not-order_status]'] = 'Terlaporkan';
        } else {
          params['filter[order_status]'] = 'Terlaporkan';
        }
      }

      if (
        this.filter.orderReportedTo == 'Kantor Pelayanan' ||
        this.filter.orderReportedTo == 'Stockist Center'
      ) {
        params['destination_office_category'] = this.filter.orderReportedTo;
      }

      return {
        'filter[destination_office_category_id]': 4,
        'filter[office_type_creator]': officeTypeCreator,
        'filter[search]': this.search ?? null,
        ...params,
      };
    },
    fetchOrderParams: function () {
      return {
        include:
          'destination-office,order-details,destination-warehouse,origin-warehouse,payments,province,city,district,village,product-bundles',
        fields: {
          orders:
            'deletedAt,latest_update_by,notes,payment_amount,grand_total_price,is_completed,origin_code,destination_code,createdAt,destination-office,created_by,area_name,area_code,order_shipment,origin-warehouse,destination-warehouse,order-details,final_price,total_point_value,total_bonus_value,grand_total_weight,is_valid_for_payment,previous_order_id,canceled_from,canceled_by_user_id,payments,payment_status,has_payment_methods,province,city,district,village,shipment_address,product-bundles',
          offices: 'code,name,address,address2,phone',
          warehouses: 'code',
          'order-details':
            'product_code,product_price,product_name,current_stock,point_value,bonus_value,product_qty,product_weight,total_price,total_weight',
          payments: 'code,createdAt,total_amount',
        },
      };
    },
    canEditOrder: function () {
      return (
        this.dataPenjualan.attributes.created_by.office.id ===
        StorageService.getUser().office_id
      );
    },
  },
  methods: {
    dayjs,
    formatDate,
    ...mapActions({
      fetchOrders: 'orders/fetchOrders',
      fetchOrder: 'orders/fetchOrder',
      updateOrder: 'orders/updateOrder',
      removeOrder: 'orders/deleteOrder',
      duplicateOrder: 'orders/duplicateOrder',
      fetchOfficeById: 'offices/fetchOfficeById',
      fetchPrevOrder: 'orders/fetchPrevOrder',
      createAlert: 'alert/createAlert',
      fetchStockMovementsByOrder: 'stock_movements/fetchStockMovementsByOrder',
      fetchStockMovement: 'stock_movements/fetchStockMovement',
    }),
    getRelationships(resource, id) {
      let data = resource.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    getRelationshipsResources(resource, relationships) {
      let data = resource.included.filter(function (el) {
        return relationships
          .map((relationship) => relationship.id)
          .includes(el.id);
      });
      return data;
    },
    debounceSearch: debounce(function () {
      this.fetchOrders({
        ...this.fetchOrdersParams,
      });
    }, 300),
    async handleDetailStockMovement(id) {
      const res = await this.fetchStockMovement({ id });

      if (res) {
        this.openDetail = false;
        this.openDetailStockMovement = true;
      }
    },
    handleCloseDetailStockMovement() {
      this.openDetailStockMovement = false;
      this.openDetail = true;
    },
    onPageChangeOrder(page) {
      this.fetchOrders({
        ...this.fetchOrdersParams,
        ...page,
      });
    },
    onClickRow(data) {
      if (
        !this.getRelationships(this.getOrders, data.relationships.order.data.id)
          .attributes.has_payment_methods
      ) {
        this.createAlert({
          data: 'Order Belum Memiliki Metode Pembayaran',
          type: 'error',
        });
        this.openOrderPaymentFormModal({
          orderId: data.attributes.order_id,
        });
      } else {
        this.openDetail = !this.openDetail;
        this.fetchStockMovementsByOrder({
          orderId: data.attributes.order_id,
        });
        this.fetchOrder({
          id: data.attributes.order_id,
          ...this.fetchOrderParams,
        }).then((response) => {
          const data = this.getOrder.data;
          this.dataPenjualan = this.getOrder.data;
          this.dataPenjualan.kode_stockist = this.getRelationships(
            response.data,
            response.data.data.relationships['destination-office'].data.id
          ).attributes.code;
          this.dataPenjualan.kode_gudang = this.getRelationships(
            response.data,
            response.data.data.relationships['origin-warehouse'].data.id
          ).attributes.code;
          // format file nomorfakturpembuat_nomorfakturdestinasi_tanggal_jam.pdf
          const createdAt = data.attributes.createdAt;
          this.cetakPdf.filename = `${data.attributes.origin_code}_${
            data.attributes.destination_code
          }_${dayjs(createdAt).format('L LTS')}`;
        });
      }
    },
    openOrderPaymentFormModal({ orderId }) {
      this.orderCreatePaymentFormProps.orderId = orderId;
      this.orderCreatePaymentFormProps.visible = true;
    },
    openEditOrder() {
      if (
        this.dataPenjualan.attributes.canceled_from &&
        this.dataPenjualan.attributes.canceled_by_user_id
      ) {
        this.confirmationEdit = !this.confirmationEdit;
      } else {
        this.$router.push(
          `/penjualan/transaksi-penjualan/${this.dataPenjualan.id}`
        );
      }
    },
    editOrder(data) {
      this.duplicateOrder({
        id: data.id,
      }).then((response) => {
        if (response) {
          this.$router.push(
            `/penjualan/transaksi-penjualan/${response.data.data.id}`
          );
        }
      });
    },

    filterByOffice() {
      // Reset Filter Status Faktur jika dipilih valid laporan && destinasi : (!sc && !kp)
      if (
        this.filter.is_valid_for_payment === 'reported' &&
        this.selectedDestinationOffice !== 'stockist_center' &&
        this.selectedDestinationOffice !== 'kantor_pelayanan'
      ) {
        this.filter.is_valid_for_payment = null;
      }

      this.fetchOrders({
        ...this.fetchOrdersParams,
      });
    },

    handleChangeFilter() {
      // Reset Filter Status Faktur jika dipilih valid laporan && origin != stockist
      if (
        this.filter.is_valid_for_payment === 'reported' &&
        this.filter.officeTypeCreator !== 'stockist'
      ) {
        this.filter.is_valid_for_payment = null;
      }

      if (this.filter.officeTypeCreator === null) {
        this.selectedDestinationOffice = null;
      }

      if (this.filter.officeTypeCreator === 'stockist') {
        this.selectedDestinationOffice = 'pusat';
      }

      this.fetchOrders({
        ...this.fetchOrdersParams,
      });
    },
    handleOpenPrevOrder: function () {
      this.fetchPrevOrder({
        id: this.dataPenjualan.id,
        ...this.fetchOrderParams,
      }).then(() => {
        this.visiblePrevOrderModal = true;
      });
    },
    async setOffice() {
      return await this.fetchOfficeById({
        office_id: StorageService.getUser().office_id,
        params: {
          'fields[offices]': 'office_type',
        },
      });
    },
    handleRefreshOrders: function () {
      this.openDetail = false;
      this.fetchOrders({
        ...this.fetchOrdersParams,
      });
    },
    handleSuccessCreatePayment: function (ordeerid) {
      this.createAlert({ data: 'Sukses Menambahkan Metode Pembayaran' });

      this.openDetail = !this.openDetail;

      this.orderCreatePaymentFormProps.orderId = null;
      this.orderCreatePaymentFormProps.visible = false;

      this.fetchOrders({
        ...this.fetchOrdersParams,
      });
      this.fetchOrder({
        id: ordeerid,
        ...this.fetchOrderParams,
      }).then((response) => {
        const data = this.getOrder.data;
        this.dataPenjualan = this.getOrder.data;
        this.dataPenjualan.kode_stockist = this.getRelationships(
          response.data,
          response.data.data.relationships['destination-office'].data.id
        ).attributes.code;
        this.dataPenjualan.kode_gudang = this.getRelationships(
          response.data,
          response.data.data.relationships['origin-warehouse'].data.id
        ).attributes.code;
        // format file nomorfakturpembuat_nomorfakturdestinasi_tanggal_jam.pdf
        const createdAt = data.attributes.createdAt;
        this.cetakPdf.filename = `${data.attributes.origin_code}_${
          data.attributes.destination_code
        }_${dayjs(createdAt).format('L LTS')}`;
      });
    },
  },
  created() {
    this.setOffice().then((res) => {
      this.filter.officeTypeCreator = res.data.data.attributes.office_type;

      this.fetchOrders({
        ...this.fetchOrdersParams,
      });
    });

    const handleEscape = () => {
      this.openDetail = false;
    };
    this.setEscape(handleEscape);
  },
};
</script>
